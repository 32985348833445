const languageSelection = async () => {
  const languageComponents = document.querySelectorAll('.select-languages');

  languageComponents.forEach(component => {
    const currentLanguage = component.querySelector('.current span');
    const options = component.querySelectorAll('.option');
    const selection = component.querySelector('.selection');
    const languages = {
      en: 'ENG',
      ru: 'RUS',
      uk: 'UKR',
    };

    const savedLanguage = languages[localStorage?.getItem('lang')];
    currentLanguage.textContent = savedLanguage || 'ENG';

    options.forEach(option => {
      option.addEventListener('click', () => {
        let textInBrackets = option.textContent.match(/\(([^)]+)\)/)[1];
        currentLanguage.textContent = textInBrackets;
        selection.style.display = 'none';
      });

      component.addEventListener('click', function () {
        if (window.innerWidth < 950) {
          if (selection.style.visibility === 'hidden') {
            selection.style.visibility = 'visible';
          } else {
            selection.style.visibility = 'hidden';
          }
        }
      });
    });

    component.addEventListener('mouseover', () => {
      selection.style.display = 'flex';
    });

    selection.addEventListener('mouseout', () => {
      selection.style.display = 'none';
    });
  });
};
document.addEventListener('DOMContentLoaded', async () => {
  await languageSelection();
});
